var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"mt-5 mt-md-0 float-right",attrs:{"color":"teal lighten-1","depressed":"","dark":""}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-account-plus")]),_c('span',{staticClass:"white--text ml-1",on:{"click":_vm.newClient}},[_vm._v("New Client")])],1),_c('v-btn',{staticClass:"mt-5 mt-md-0 mr-2 float-right",attrs:{"text":"","loading":_vm.xlsxLoading,"outlined":""},on:{"click":_vm.xlsxExport},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('v-progress-circular',{attrs:{"indeterminate":"","color":"red darken-4","width":"2","size":"28"}})]},proxy:true}])},[_vm._v(" Export XLSX ")])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"elevation-0",attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"custom-filter":_vm.filter,"headers":_vm.headers,"fixed-headers":"","loading":_vm.loading,"loading-text":"Loading clients...","items":_vm.clients,"item-key":"clientId","search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Clients")]),_c('v-divider',{staticClass:"mx-4",attrs:{"vertical":""}}),_c('div',{staticClass:"flex-grow-1 hidden-sm-and-down"}),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('div',{staticClass:"flex-grow-1"})],1)]},proxy:true},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.showPreview(item)}}},on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("View client")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.editItem(item)}}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit client")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.deleteItem(item)}}},on),[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete client")])])]}},{key:"no-data",fn:function(){return [_c('p',[_vm._v("No data to display")])]},proxy:true}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1),_c('ViewClient',{attrs:{"is-open":_vm.viewDialogOpen,"client":_vm.viewDialogClient},on:{"onCloseDialog":function($event){_vm.viewDialogOpen = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }