<!-- eslint-disable vue/valid-v-slot -->
<template>
  <Layout>
    <v-row>
      <v-col cols="12">
        <v-btn
          color="teal lighten-1"
          depressed
          dark
          class="mt-5 mt-md-0 float-right"
        >
          <v-icon color="white">mdi-account-plus</v-icon>
          <span class="white--text ml-1" @click="newClient">New Client</span>
        </v-btn>
        <v-btn
          text
          :loading="xlsxLoading"
          outlined
          class="mt-5 mt-md-0 mr-2 float-right"
          @click="xlsxExport"
        >
          <template v-slot:loader>
            <v-progress-circular
              indeterminate
              color="red darken-4"
              width="2"
              size="28"
            />
          </template>
          Export XLSX
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-card class="elevation-0" outlined>
          <v-data-table
          v-model="selected"
            :custom-filter="filter"
            :headers="headers"
            fixed-headers
            :loading="loading"
            loading-text="Loading clients..."
            :items="clients"
            item-key="clientId"
            :search="search"
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-toolbar-title>Clients</v-toolbar-title>
                <v-divider vertical class="mx-4"></v-divider>
                <div class="flex-grow-1 hidden-sm-and-down"></div>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
                <div class="flex-grow-1"></div>
              </v-toolbar>
            </template>
            <template v-slot:item.action="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" @click.stop="showPreview(item)">
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>View client</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" @click="editItem(item)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit client</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" @click="deleteItem(item)">
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </template>
                <span>Delete client</span>
              </v-tooltip>
            </template>
            <template v-slot:no-data>
              <p>No data to display</p>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
     <ViewClient
      :is-open="viewDialogOpen"
      :client="viewDialogClient"
      @onCloseDialog="viewDialogOpen = false"
    />
  </Layout>
</template>
<script>
import Layout from '@layouts/main'
import ViewClient from '@components/Clients/ViewClient.vue'
import api from '@src/api/index.js'
import EventBus from '@utils/EventBus'
import { EventBusEvents, ClientTypes } from '@src/constants/index.js'

export default {
  name: 'Clients',
  components: {
    Layout,
    ViewClient,
  },
  data: () => ({
    users: [],
    viewDialogOpen: false,
    viewDialogClient: null,
    files: [],
    clients: [],
    xlsxLoading: false,
    search: '',
    saveDisabled: true,
    selected: [],
    editedIndex: -1,
    headers: [
      {
        text: 'Client',
        value: 'clientName',
        align: 'left',
      },
      {
        text: 'Type',
        value: 'clientType',
        align: 'left',
      },
      {
        text: 'Email',
        value: 'email',
        align: 'left',
      },
      {
        text: 'Mobile',
        value: 'mobileNumber',
        align: 'left',
        sortable: false,
      },
      {
        text: 'Status',
        value: 'status',
        align: 'left',
      },
      {
        text: 'Actions',
        value: 'action',
        align: 'center',
        sortable: false,
      },
    ],
    dialog: false,
    modal: false,
    loading: false,
    modalAction: null,
  }),
  mounted() {
    this.getAllClients()
  },
  methods: {
    filter(value, search, item) {
      const { clientName, clientType, companyName, email, status } = item
      const name =
        clientType === ClientTypes.INDIVIDUAL ? clientName : companyName

      return (
        name.toLowerCase().includes(search.toLowerCase()) ||
        email.toLowerCase().includes(search.toLowerCase()) ||
        clientType.toLowerCase().includes(search.toLowerCase()) ||
        status.toLowerCase().includes(search.toLowerCase())
      )
    },
    getClientName({ clientName, clientType, companyName }) {
      return clientType === ClientTypes.INDIVIDUAL ? clientName : companyName
    },
    forceFileDownload(blob, fileName) {
      const a = window.document.createElement('a')
      a.href = window.URL.createObjectURL(blob)
      a.download = 'export.xlsx'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
    showPreview(item) {
      this.viewDialogOpen = true
      this.viewDialogClient = item
    },
    newClient() {
      this.$router.push({ name: 'new-client' })
    },
    async editItem(item) {
      const { clientId } = item
      try {
        const lockId = await api.lockEditClient(clientId)
        this.$router.push({ name: 'edit-client', params: { item, lockId } })
      } catch (err) {
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      }
    },
    deleteItem({ clientId }) {
      EventBus.$emit(EventBusEvents.OPEN_MODAL, {
        title: 'Delete client',
        message: 'Are you sure to delete this client?',
        confirmAction: () => this.deleteClient(clientId),
      })
    },
    async deleteClient(clientId) {
      this.loading = true
      try {
        await api.deleteClient(clientId)
        this.loading = false
        this.getAllClients()
      } catch (err) {
        this.loading = false
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      }
    },
    async getAllClients() {
      this.loading = true
      try {
        const clients = await api.getAllClients();
        clients.forEach(element => {
          if(element) {
            if(element.companyName) {
              element.clientName=element.companyName;
            }
          }
        });
        this.clients = clients
      } catch (err) {
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      } finally {
        this.loading = false
      }
    },
    async xlsxExport() {
      this.xlsxLoading = true
      try {
        const xlsx = await api.generateXlsx()
        this.forceFileDownload(xlsx)
      } catch (err) {
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      } finally {
        this.xlsxLoading = false
      }
    },
  },
}
</script>
<style>
.fixed--bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
}
</style>
