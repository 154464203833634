<!-- eslint-disable vue/valid-v-slot -->
<template>
  <v-dialog
  v-model="dialog"
  max-width="1200px"
  >
    <v-card class="pa-10">
       <v-card-text>
     <img
     v-if="extension !== 'pdf'"
     class="img-class"
     :src="'data:image/png;base64,' + documentBlob" />
     <iframe 
     v-if="extension === 'pdf'"
     class="frame-class"
     width=100% :src="'data:application/pdf;base64,' + documentBlob" ></iframe>
       </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'ViewDocument',
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    client: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      search: '',
      dialog: false,
      documentBlob: '',
      extension: '',
    }
  },
  watch: {
    isOpen(dialogOpen) {
      this.dialog = dialogOpen

      if (dialogOpen) {
       this.documentBlob = this.client.documentBlob
       this.extension = this.client.extension
      }
    },
    dialog(dialogOpen) {
      if (!dialogOpen) {
        this.$emit('onCloseDialog')
      }
    },
  },
}
</script>
<style>
.overflow-y-scroll {
  overflow-y: scroll;
}
.card-class {
  width: 1200px !important;
  height: auto !important;
}
.img-class {
  width: 1070px !important;
}
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
}
.frame-class {
  min-height: 600px;
}
</style>
