<!-- eslint-disable vue/valid-v-slot -->
<template>
<Layout>
      <ViewDocument
      :is-open="viewDocDialogOpen"
      :client="viewDialogClient"
      @onCloseDialog="viewDocDialogOpen = false"
    />
  <v-dialog v-model="dialog">
    <v-card class="pa-10">
      <v-row>
        <v-col cols="12" :md="isCorporateClient ? 4 : 12">
          <v-card class="overflow-y-scroll elevation-0" outlined>
            <v-card-title>Client data</v-card-title>
            <v-card-text>
              <p
                v-for="(clientField, k) in fieldsByClientType"
                :key="`client-resume-${k}`"
              >
                <span>{{ clientField.field }}:</span>
                <span>{{ clientField.value }}</span>
              </p>
              <p>
                <span>Uploaded documents:</span>
              </p>
              <v-row>
                <v-col cols="12">
                  <v-chip-group active-class="primary--text" column>
                    <v-chip
                      v-for="(documentTag, index) in documentsTag"
                      :key="`doc-tag-${index}`"
                      small
                      >{{ documentTag }}</v-chip
                    >
                  </v-chip-group>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col v-if="isCorporateClient" cols="12" md="8">
          <v-card height="300" class="overflow-y-scroll elevation-0" outlined>
            <v-card-title>Shareholders</v-card-title>
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="shareholders"
                sort-by="shareholderId"
                class="elevation-0"
                dense
                :search="search"
                no-data-text="No shareholders available"
                :loading="false"
                loading-text="Loading shareholders..."
              >
                <template v-slot:item.name="{ item }">
                  {{ shareholderName(item) }}
                </template>
                <template v-slot:item.address="{ item }">
                  {{ item.address }}
                </template>
                <template v-slot:item.type="{ item }">
                  {{ item.shareholderType }}
                </template>
                <template v-slot:item.documents="{ item }">
                  <v-row>
                    <v-col cols="12">
                      <v-chip-group active-class="primary--text" column>
                        <v-chip
                          v-for="(document, index) in item.documents"
                          :key="`chips-documents-${index}`"
                          small
                          @click="getFile(document)"
                          >{{ documentShareholder(document) }}</v-chip
                        >
                      </v-chip-group>
                    </v-col>
                  </v-row>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</Layout>
</template>
<script>
import {
  EventBusEvents,
  documentTypes,
  ClientTypes,
  ClientTypesFull,
} from '@src/constants/index.js'
import EventBus from '@utils/EventBus'
import api from '@src/api/index.js'
import Layout from '@layouts/main'
import ViewDocument from '@components/Clients/ViewDocument.vue'
export default {
  name: 'ViewClient',
  components: {
    Layout,
    ViewDocument,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    client: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      search: '',
      dialog: false,
      shareholders: [],
      documents: [],
      viewDocDialogOpen: false,
      viewDialogClient: null,
      headers: [
        {
          text: 'Name',
          value: 'name',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Address',
          value: 'address',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Type',
          value: 'type',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Documents',
          value: 'documents',
          align: 'left',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    isCorporateClient() {
      return (
        this.client &&
        this.client.clientType &&
        this.client.clientType === ClientTypes.CORPORATE
      )
    },
    fieldsByClientType() {
      if (!this.client || !this.client.clientType) {
        return []
      }

      return this.clientFields.filter(({ types, subtypes }) => {
        if (this.client.clientType === ClientTypesFull.SHAREHOLDER) {
          return (
            types.includes(this.client.clientType) &&
            subtypes.includes(this.client.shareholderType)
          )
        }
        return types.includes(this.client.clientType)
      })
    },
    clientFields() {
      return [
        {
          field: 'Status',
          value: (this.client && this.client.status) || 'n/a',
          types: [ClientTypesFull.INDIVIDUAL, ClientTypesFull.CORPORATE],
        },
        {
          field: 'Client Name',
          value:
            (this.client && this.client.clientName) ||
            (this.client && this.client.name) ||
            'n/a',
          types: [ClientTypesFull.INDIVIDUAL, ClientTypesFull.SHAREHOLDER],
          subtypes: [ClientTypes.INDIVIDUAL],
        },
        {
          field: 'Company Name',
          value: (this.client && this.client.companyName) || 'n/a',
          types: [ClientTypesFull.CORPORATE, ClientTypesFull.SHAREHOLDER],
          subtypes: [ClientTypes.CORPORATE],
        },
        {
          field: 'Passport/Id card',
          value: (this.client && this.client.passport) || 'n/a',
          types: [ClientTypesFull.SHAREHOLDER],
          subtypes: [ClientTypes.INDIVIDUAL],
        },
        {
          field: 'Email',
          value: (this.client && this.client.email) || 'n/a',
          types: [
            ClientTypesFull.INDIVIDUAL,
            ClientTypesFull.CORPORATE,
            ClientTypesFull.SHAREHOLDER,
          ],
          subtypes: [ClientTypes.INDIVIDUAL, ClientTypes.CORPORATE],
        },
        {
          field: 'Mobile Number',
          value: (this.client && this.client.mobileNumber) || 'n/a',
          types: [
            ClientTypesFull.INDIVIDUAL,
            ClientTypesFull.CORPORATE,
            ClientTypesFull.SHAREHOLDER,
          ],
          subtypes: [ClientTypes.INDIVIDUAL, ClientTypes.CORPORATE],
        },
        {
          field: 'Address',
          value: (this.client && this.client.address) || 'n/a',
          types: [ClientTypesFull.INDIVIDUAL, ClientTypesFull.SHAREHOLDER],
          subtypes: [ClientTypes.INDIVIDUAL],
        },
        {
          field: 'Nationality',
          value: (this.client && this.client.nationality) || 'n/a',
          types: [ClientTypesFull.INDIVIDUAL, ClientTypesFull.SHAREHOLDER],
          subtypes: [ClientTypes.INDIVIDUAL],
        },
        {
          field: 'Country',
          value: (this.client && this.client.country) || 'n/a',
          types: [ClientTypesFull.INDIVIDUAL, ClientTypesFull.SHAREHOLDER],
          subtypes: [ClientTypes.INDIVIDUAL],
        },
        {
          field: 'VAT Number',
          value: (this.client && this.client.vatNumber) || 'n/a',
          types: [ClientTypesFull.INDIVIDUAL, ClientTypesFull.CORPORATE],
        },
        {
          field: 'Registration Address',
          value:
            (this.client && this.client.registrationAddress) ||
            (this.client && this.client.address) ||
            'n/a',
          types: [ClientTypesFull.CORPORATE, ClientTypesFull.SHAREHOLDER],
          subtypes: [ClientTypes.CORPORATE],
        },
        {
          field: 'Registration Number',
          value: (this.client && this.client.registrationNumber) || 'n/a',
          types: [ClientTypesFull.CORPORATE, ClientTypesFull.SHAREHOLDER],
          subtypes: [ClientTypes.CORPORATE],
        },
        {
          field: 'Incorporation Country',
          value: (this.client && this.client.incorporationCountry) || 'n/a',
          types: [ClientTypesFull.SHAREHOLDER],
          subtypes: [ClientTypes.CORPORATE],
        },
        {
          field: 'Contact Person',
          value: (this.client && this.client.contactPerson) || 'n/a',
          types: [ClientTypesFull.CORPORATE],
        },
        {
          field: 'Director',
          value: (this.client && this.client.director) || 'n/a',
          types: [ClientTypesFull.CORPORATE, ClientTypesFull.SHAREHOLDER],
          subtypes: [ClientTypes.CORPORATE],
        },
        {
          field: 'Incorporation Date',
          value: (this.client && this.client.incorporationDate) || 'n/a',
          types: [ClientTypesFull.CORPORATE, ClientTypesFull.SHAREHOLDER],
          subtypes: [ClientTypes.CORPORATE],
        },
      ]
    },
    documentsTag() {
      return this.documents.map(({ type }) => documentTypes[type])
    },
  },
  watch: {
    isOpen(dialogOpen) {
      this.dialog = dialogOpen

      if (dialogOpen) {
        this.fetchClientInfo()
      }
    },
    dialog(dialogOpen) {
      if (!dialogOpen) {
        this.$emit('onCloseDialog')
      }
    },
  },
  methods: {
    documentShareholder({ type }) {
      return documentTypes[type]
    },
    async fetchClientInfo() {
      const { clientId } = this.client
      try {
        this.documents = await api.getDocumentsByClientId(clientId)
        if (this.isCorporateClient) {
          const shareholders = await api.getShareholdersByClientShareholders(
            clientId
          )

          for (const shareholder of shareholders) {
            const { clientId: clientShareholderId } = shareholder
            const documents = await api.getDocumentsByClientId(
              clientShareholderId
            )
            shareholder.documents = documents
          }
          this.shareholders = shareholders
        }
      } catch (err) {
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      }
    },
    shareholderName({ name, shareholderType, companyName }) {
      return shareholderType === ClientTypes.INDIVIDUAL ? name : companyName
    },
    async getFile (docData) {
      var extension = docData.documentName.split('.').pop();
      const data = await api.getDocumentById(docData.documentId)
      if(data.documentBlob) {
        data.extension = extension;
        this.viewDocDialogOpen = true
        this.viewDialogClient = data
      } else {
          EventBus.$emit(
          EventBusEvents.SNACKBAR_ERROR,
          {
            message:
              'Document not found!',
          },
          this
        )
      }
    }
  },
}
</script>
<style>
.overflow-y-scroll {
  overflow-y: scroll;
}
</style>
