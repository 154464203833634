var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('ViewDocument',{attrs:{"is-open":_vm.viewDocDialogOpen,"client":_vm.viewDialogClient},on:{"onCloseDialog":function($event){_vm.viewDocDialogOpen = false}}}),_c('v-dialog',{model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pa-10"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":_vm.isCorporateClient ? 4 : 12}},[_c('v-card',{staticClass:"overflow-y-scroll elevation-0",attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Client data")]),_c('v-card-text',[_vm._l((_vm.fieldsByClientType),function(clientField,k){return _c('p',{key:("client-resume-" + k)},[_c('span',[_vm._v(_vm._s(clientField.field)+":")]),_c('span',[_vm._v(_vm._s(clientField.value))])])}),_c('p',[_c('span',[_vm._v("Uploaded documents:")])]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-chip-group',{attrs:{"active-class":"primary--text","column":""}},_vm._l((_vm.documentsTag),function(documentTag,index){return _c('v-chip',{key:("doc-tag-" + index),attrs:{"small":""}},[_vm._v(_vm._s(documentTag))])}),1)],1)],1)],2)],1)],1),(_vm.isCorporateClient)?_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-card',{staticClass:"overflow-y-scroll elevation-0",attrs:{"height":"300","outlined":""}},[_c('v-card-title',[_vm._v("Shareholders")]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.shareholders,"sort-by":"shareholderId","dense":"","search":_vm.search,"no-data-text":"No shareholders available","loading":false,"loading-text":"Loading shareholders..."},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.shareholderName(item))+" ")]}},{key:"item.address",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.address)+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.shareholderType)+" ")]}},{key:"item.documents",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-chip-group',{attrs:{"active-class":"primary--text","column":""}},_vm._l((item.documents),function(document,index){return _c('v-chip',{key:("chips-documents-" + index),attrs:{"small":""},on:{"click":function($event){return _vm.getFile(document)}}},[_vm._v(_vm._s(_vm.documentShareholder(document)))])}),1)],1)],1)]}}],null,false,42639421)})],1)],1)],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }